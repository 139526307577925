.titleBigContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

@keyframes dropFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.titleBigText {
  animation-delay: 300ms;
  animation-direction: normal;
  animation-duration: 900ms;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-name: dropFadeIn;
  animation-play-state: running;
  animation-timing-function: ease;
  font-size: min(9vw, 5rem);
  font-weight: 500;
  line-height: 110%; /* bleh, wanted 90%, but multiline text clips */
  /* Orange gradient text*/
  background-image: linear-gradient(to bottom right, #ff8a00 40%, #dd4c4f 100%);
  background-clip: text;
  color: transparent;
}
