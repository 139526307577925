.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.video {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}
