.header {
  backdrop-filter: blur(10px);
  box-shadow: inset 0 0 3rem -1rem #ddd;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  flex-direction: row;
  height: 4rem;
  justify-content: space-between;
  margin: 0 auto;
  position: fixed;
  top: 0;
  width: min(100vw, 1200px);
  z-index: 10; /* Render over other content that compete to get in front. */
}

.headerLeft,
.headerRight {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.headerLeft .logo {
  height: 2.5rem;
  margin: 0.75rem 0rem 0.75rem 1.5rem;
}
.headerLeft .logotext {
  height: 2rem;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.headerLeft .tagline {
  margin-left: 1.5rem;
}

.header .menu {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: min(0vw, 1.5rem);
  margin: 0 min(0, 1.5rem);
}

.header .menu button {
  background-color: transparent;
  border: none;
  color: #444;
  cursor: pointer;
  font-size: min(2.5vw, 1.1rem);
  margin: 0.5rem;
}

.headerRight a.mailto {
  color: inherit;
  text-decoration: none;
  margin-right: 1.5rem;
}
.headerRight a.mailto:hover {
  text-decoration: underline;
}
.headerRight a.mailto:visited {
  color: inherit;
}
.headerRight a.mailto:visited:hover {
  text-decoration: underline;
}


.headerRight .demoButton {
  animation: none;
  font-size: min(4vw, 1rem);
  margin: 0.5rem 1.5rem 0.5rem 0;
  white-space: nowrap;
}

@media (max-width: 650px) {
  .headerLeft .tagline {
    display: none;
  }
  .headerRight .mailto {
    display: none;
  }
}