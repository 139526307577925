:root {
  background-color: #fafafa;
  color: #444;
}

#root {
  font-family: "Source Sans 3", sans-serif;
  overflow-x: hidden;
}

.app {
  background-color: #f9f8f8;
  display: flex;
  justify-content: center;
}

.center {
  width: min(100vw, 1200px);
}

.content {
  display: grid;
  row-gap: min(20vw, 5rem);
  grid-auto-rows: auto;
  margin-top: 11rem;
}

.finalSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75vh;
  min-width: min(100vw, 100%);
}

.finalSection .titleSubContainer {
  margin-top: 0;
  margin-bottom: 0;
}

.screenshotDashboardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0rem;
  margin-top: min(5vw, 3rem);
}

.screenshotDashboard1 {
  position: relative;
  box-shadow: 0 0.5rem 1rem -0.3rem grey;
  width: min(20vw, 14.5rem);
  height: auto;
  transform: rotate(-2deg);
  transition: transform 150ms ease-in-out, scale 150ms ease-in-out;
  z-index: 1;
}
.screenshotDashboard1:hover {
  scale: 1.05;
  transform: rotate(-5deg);
}

.screenshotDashboard2 {
  position: relative;
  box-shadow: 0 0.5rem 1rem -0.3rem grey;
  width: min(66vw, 50rem);
  transform: rotate(2deg);
  transition: transform 150ms ease-in-out, scale 150ms ease-in-out;
}
.screenshotDashboard2:hover {
  scale: 1.05;
  transform: rotate(5deg);
}

.bigTitleMatrixOrg {
  position: relative;
}

.screenshotMatrix {
  width: 120%;
  position: relative;
  left: -10%;
  max-width: 2905px;
}

.screenshotOnboarding1 {
  position: relative;
  box-shadow: 0 0.5rem 1rem -0.3rem grey;
  width: min(50vw, 32rem);
  transform: rotate(-2deg);
  transition: transform 150ms ease-in-out, scale 150ms ease-in-out;
}
.screenshotOnboarding1:hover {
  scale: 1.05;
  transform: rotate(-5deg);
}

.screenshotOnboarding2 {
  position: relative;
  box-shadow: 0 0.5rem 1rem -0.3rem grey;
  width: min(34vw, 22rem);
  transform: rotate(2deg);
  transition: transform 150ms ease-in-out, scale 150ms ease-in-out;
}
.screenshotOnboarding2:hover {
  scale: 1.05;
  transform: rotate(5deg);
}
