.footer {
  font-size: min(3vw, 1rem);
  padding: min(7vw, 4rem) min(5vw, 3rem);
}

.footerContent {
  display: grid;
  grid-template-columns: auto auto;
}

.disclaimer {
  color: #ccc;
  display: block;
  font-size: min(2.5vw, 0.75rem);
  margin-top: min(7vw, 1.5rem);
  text-align: center;
}
