.screenshotContainer {
  width: auto;
  margin: 9rem 10% 0 10%;
  perspective: 1000px;
}

@keyframes threeDFadeIn {
  0% {
    border: 1px solid #ccc;
    box-shadow: 0rem 3rem 0.2rem -1.8rem rgb(0, 0, 0, 0.3);
    scale: 0.8;
    transform: rotateX(30deg);
  }
  100% {
    border: 1px solid transparent;
    box-shadow: 0rem 1.1rem 1.2rem 0rem rgba(0, 0, 0, 0.3);
    scale: 1;
    transform: rotateX(0deg);
  }
}

.screenshotContainer img {
  animation-delay: 900ms;
  animation-direction: normal;
  animation-duration: 900ms;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-name: threeDFadeIn;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  box-shadow: 0rem 1.1rem 1.2rem 0rem rgba(0, 0, 0, 0.3);
  transition: box-shadow, transform, scale;
  width: 100%;
}
