.titleSubContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: min(3vw, 1.5rem);
  margin-bottom: min(4vw, 2rem);
  padding: 0 10%;
  text-align: center;
}

@keyframes dropFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.titleSubText {
  animation-delay: 500ms;
  animation-direction: normal;
  animation-duration: 900ms;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-name: dropFadeIn;
  animation-play-state: running;
  animation-timing-function: ease;
  color: #666;
  font-size: min(3.2vw, 1.5rem);
  font-weight: 400;
}
