.demoButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: min(5vw, 3rem);
}

.demoButton {
  animation-delay: 700ms;
  animation-direction: normal;
  animation-duration: 900ms;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-name: dropFadeIn;
  animation-play-state: running;
  animation-timing-function: ease;
  background-image: linear-gradient(to bottom right, #ff8a00 40%, #dd4c4f 100%);
  border: 1px solid transparent;
  border-radius: 99rem;
  color: #fff;
  cursor: pointer;
  font-size: min(4.5vw, 1.2rem);
  font-weight: 400;
  padding: 0.7rem 2rem;
  text-decoration: none;
  transition: background-color, border-color, color;
}
