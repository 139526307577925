.featureGridContainer {
  display: grid;
  gap: min(2vw, 1.5rem);
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem min(2vw, 2rem);
}

.featureGridContainerTwoColumns {
  grid-template-columns: 1fr 1fr;
}

.featureGridItem {
  background-color: #fff;
  border-radius: 0.3rem;
  font-size: min(3vw, 1rem);
  min-height: 2rem;
  padding: min(2vw, 1rem);
}

.featureBold {
  font-weight: 600;
}
.featureText {
  color: #777;
}
